@import '../../../scss/theme-bootstrap';

#footer .footer-bottom {
  .block {
    div {
      .popup-offer-v1 {
        .hidden {
          display: none;
        }
        .form-submit-wrap {
          margin: 20px;
          display: flex;
        }
      }
    }
  }
}

.popup-offer-v1 {
  background: $color-white;
  position: relative;
  .popup-accept-message,
  .tooltip-gdpr {
    margin: 0.5em 2em;
  }
}

.page-popup {
  z-index: 9997;
}

.popup-offer-v1 .outer-wrap {
  background: none repeat scroll 0 0 $color-white;
  border: 5px solid $color-black;
  height: 353px;
  #{$ldirection}: 37.3%;
  overflow: hidden;
  /* padding: 20px 20px 15px; */
  @include swap_direction(padding, 20px 20px 15px);
  position: fixed; /* position: absolute; */
  top: 20%; /* top: 1.2%; */
  width: 466px;
  z-index: 10000; /* z-index: 1000; */
}

.page-popup .outer-wrap {
  height: 353px;
  #{$ldirection}: 37.3%;
  overflow: hidden;
  /* padding: 20px 20px 15px; */
  @include swap_direction(padding, 20px 20px 15px);
  position: fixed; /* position: absolute; */
  width: 466px;
  top: 50%;
  transform: translateY(-50%);
  .form-submit.button {
    background-color: $color-black;
  }
  #popover-newsletter-signup {
    text-align: center;
  }
}

.page-popup {
  .page-popup-blanket {
    width: 100%;
    height: 100%;
    background-color: $color-black;
    position: fixed;
    top: 0;
    opacity: 0.6;
  }
}

.popup-offer-v1 .popup-offer-close {
  background: transparent url($img_base_path + '/icon-close.png') no-repeat scroll 0 0 / 15px 15px;
  cursor: pointer;
  height: 15px;
  position: absolute;
  #{$rdirection}: 5px;
  text-align: #{$rdirection};
  top: 5px;
  width: 15px;
}

.ie8 .popup-offer-v1 .popup-offer-close {
  background: transparent url($img_base_path + '/icon-close.png') no-repeat scroll 0 0;
  height: 22px;
  width: 22px;
}

.popup-offer-v1 .popup-offer-image {
  float: #{$ldirection};
  height: auto;
  /* margin: 0 50px 0 0; */
  @include swap_direction(margin, 0 50px 0 0);
  max-width: 120px;
}

.popup-offer-v1 h2 {
  color: $color-black;
  line-height: 1;
  /* margin: 40px 0 20px; */
  @include swap_direction(margin, 40px 0 20px);
  text-align: center;
}

.page-popup .thankyou h2 {
  //@include swap_direction(margin, 80px 0 20px);
}

.page-popup h2 {
  font-size: 26px;
}

.popup-offer-v1 .subheading {
  color: $color-black;
  font-size: 26px;
  font-family: $helvetica-light-font-family;
  line-height: 1;
  /* margin: 0 0 50px; */
  @include swap_direction(margin, 0 0 50px);
}

.page-popup .subheading {
  @include swap_direction(margin, 0 0 40px);
  //font-family: $base-bold-font-family;
}

.popup-offer-v1 .subheading p {
  color: $color-cl-green;
  font-size: 23px;
  line-height: 1;
  margin: 0;
  text-align: center;
}

.page-popup .subheading p {
  color: $color-black;
}

.page-popup-background {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  #{$ldirection}: 37.3%;
  height: 353px;
  width: 466px;
  border: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-attachment: scroll;
  background-clip: border-box;
  background-origin: padding-box;
  background-position: 0 0;
  background-repeat: no-repeat;
}

.page-popup .outer-wrap {
  background: none;
}

.popup-offer-v1 .subheading p span {
  border-bottom: 1px solid $color-black;
  border-top: 1px solid $color-black;
  font-weight: bold;
  text-transform: uppercase;
}

.popup-offer-v1 .popup-offer-footer-msg {
  bottom: 15px;
  color: $color-black;
  font-size: 14px;
  font-family: $helvetica-regular-font-family;
  #{$ldirection}: 4%;
  position: absolute;
  width: 90%;
}

.page-popup .popup-offer-footer-msg {
  #{$ldirection}: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.popup-offer-v1 .form-submit-wrap {
  margin: 0 20px;
}

.popup-offer-v1 form .form-submit-wrap .form-text {
  height: 38px;
  width: 74%;
}

.page-popup form .form-submit-wrap .form-text {
  width: 50%;
}

.popup-offer-v1 form .form-submit-wrap input.form-submit,
.popup-offer-v1 form .form-submit-wrap input.button[type='submit'] {
  font-size: 1.3em;
  line-height: 1.5;
  margin-#{$ldirection}: -4px;
  padding: 6px 10px;
  vertical-align: -2px;
}

.popup-offer-v1 .outer-wrap.signedup h2,
.popup-offer-v1 .outer-wrap.thankyou h2 {
  color: $color-cl-green;
}

.popup-offer-v1 .outer-wrap.signedup .subheading p,
.popup-offer-v1 .outer-wrap.thankyou .subheading p {
  color: $color-black;
  font-size: 23px;
  line-height: 1;
  margin: 0;
  text-align: center;
}

.popup-offer-v1 .outer-wrap.signedup .subheading p span {
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
  font-weight: bold;
  text-transform: uppercase;
}

.popup-offer-v1 .outer-wrap.thankyou .popup-offer-footer-msg {
  #{$ldirection}: 20%;
}

.popup-page .outer-wrap.thankyou .popup-offer-footer-msg {
  #{$ldirection}: 0;
}

.popup-offer-v1 .outer-wrap.signedup .cta,
.popup-offer-v1 .outer-wrap.thankyou .cta {
  /* margin: 50px auto 0; */
  @include swap_direction(margin, 50px auto 0);
  text-align: center;
}

.popup-offer-v1 .outer-wrap.signedup .cta a.button,
.popup-offer-v1 .outer-wrap.thankyou .cta a.button {
  font-size: 1.9em;
  /* padding: 4px 20px 3px; */
  @include swap_direction(padding, 4px 20px 3px);
}
/* PG PC */
.pg .outer-wrap.signup,
.pg .outer-wrap.thankyou,
.pg .outer-wrap.signedup {
  top: 25%;
}

/*
 * Clinique CA: There is an issue on CA happening on the latest IE versions: 8/9/10/11.
 * This was reported on IE11, but I found it on IE8 and up. Since seems that there is no
 * specific CSS IE classes that can be used in this case, only for IE8 (in the HTML tag),
 * and the fix will require a small change in font size, from 1.3em to 1.27em,
 * I'm addding it globally to all browsers on CL CA.
 */
.domain-ca .popup-offer-v1 form .form-submit-wrap input.form-submit,
.domain-ca .popup-offer-v1 form .form-submit-wrap input.button[type='submit'] {
  font-size: 1.27em;
}
/* Mobile */
.device-mobile .popup-offer-v1 .outer-wrap {
  border: 5px solid $color-black;
  height: 275px;
  #{$ldirection}: 7%;
  /* padding: 20px 20px 15px; */
  @include swap_direction(padding, 20px 20px 15px);
  position: fixed;
  top: 10%;
  width: 315px;
}

.device-mobile .popup-offer-v1 .popup-offer-close {
  background: transparent url($img_base_path + '/icon-close.png') no-repeat scroll 0 0;
  height: 22px;
  #{$rdirection}: 5px;
  width: 22px;
}

.device-mobile .popup-offer-v1 h2,
.device-mobile .popup-offer-v1 .outer-wrap.signedup h2,
.device-mobile .popup-offer-v1 .outer-wrap.thankyou h2 {
  font-size: 26px;
  margin-bottom: 20px;
}

.device-mobile .popup-offer-v1 .subheading {
  /* margin: 0 0 20px; */
  @include swap_direction(margin, 0 0 20px);
}

.device-mobile .popup-offer-v1 .subheading p,
.device-mobile .popup-offer-v1 .outer-wrap.signedup .subheading p,
.device-mobile .popup-offer-v1 .outer-wrap.thankyou .subheading p {
  font-size: 16px;
}

.device-mobile .popup-offer-v1 .outer-wrap.thankyou .popup-offer-footer-msg {
  #{$ldirection}: 5%;
}

.device-mobile .popup-offer-v1 form .form-submit-wrap .form-text {
  height: 30px;
  width: 70%;
}

.device-mobile .popup-offer-v1 form .form-submit-wrap input.form-submit,
.device-mobile .popup-offer-v1 form .form-submit-wrap input.button[type='submit'] {
  font-size: 0.9em;
  height: 30px;
  line-height: 0.9;
  margin-#{$ldirection}: -4px;
  padding: 4px 5px;
  vertical-align: baseline;
  width: 30%;
}

.device-mobile .popup-offer-v1 .outer-wrap.signedup .cta,
.device-mobile .popup-offer-v1 .outer-wrap.thankyou .cta {
  margin-top: 20px;
}

.device-mobile .popup-offer-v1 .outer-wrap.signedup .cta a.button,
.device-mobile .popup-offer-v1 .outer-wrap.thankyou .cta a.button {
  font-size: 1.3em;
  height: 37px;
  line-height: 1.5;
  padding: 5px;
}
/* iPhone 3, 4 and 5 portrait */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
  .device-mobile .popup-offer-v1 .outer-wrap {
    #{$ldirection}: 0;
  }
}
/* iPhone 3, 4 and 5 landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
  .device-mobile .popup-offer-v1 .outer-wrap {
    #{$ldirection}: 17%;
    top: 5%;
  }
}
/* iPhone 6 portrait */

@media only screen and (min-device-width: 374px) and (max-device-width: 376px) and (orientation: portrait) {
  .device-mobile .popup-offer-v1 .outer-wrap {
    #{$ldirection}: 8%;
  }
}
/* iPhone 6 landscape */

@media only screen and (min-device-width: 374px) and (max-device-width: 376px) and (orientation: landscape) {
  .device-mobile .popup-offer-v1 .outer-wrap {
    #{$ldirection}: 30%;
  }
}
/* iPhone 6 Plus portrait */

@media only screen and (min-device-width: 413px) and (max-device-width: 415px) and (orientation: portrait) {
  .device-mobile .popup-offer-v1 .outer-wrap {
    #{$ldirection}: 10%;
  }
}
/* iPhone 6 Plus landscape */

@media only screen and (min-device-width: 413px) and (max-device-width: 415px) and (orientation: landscape) {
  .device-mobile .popup-offer-v1 .outer-wrap {
    #{$ldirection}: 30%;
  }
}
/* Samsung Galaxy S5, Samsung Galaxy Note 3 & LARGER SCREENS SMARTPHONES PORTRAIT & LANDSCAPE */

@media only screen and (device-width: 360px) and (device-height: 640px) and (resolution: 288dpi) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .device-mobile .popup-offer-v1 .outer-wrap {
    #{$ldirection}: 7%;
    top: 20%;
  }
}

@media only screen and (min-device-height: 279px) and (min-device-width: 640px) and (resolution: 288dpi) and (orientation: landscape) {
  .device-mobile .popup-offer-v1 .outer-wrap {
    #{$ldirection}: 30%;
  }
}

@media screen and (max-width: 1024px) {
  .popup-offer-v1 h2 {
    font-size: 26px;
    /* margin: 15px 0 0 0; */
    @include swap_direction(margin, 15px 0 0 0);
  }
  .popup-offer-v1 .subheading {
    font-size: 18px;
  }
  .popup-offer-v1 .popup-offer-close,
  .popup-offer-v1 .popup-offer-footer-msg {
    #{$rdirection}: 20px;
  }
}
